import { Product, ProductStatus, ResourceType } from "../../types";
import { proxyShopifyGraphqlRequest } from "../proxyRequest";
import {
  getIdFromGqlId,
  getLastCursorFromEdges,
  GqlResponseParser,
  PaginatedGraphqlResponse,
  parseGqlResponse,
} from "./shared";
import { getVariantFromNode, getVariantNodeQuery } from "./variant";

export async function getShopifyMultipleProductsSemiShallow({
  accessToken,
  shopId,
  nProducts,
  nVariantsPerProduct,
  cursor,
  searchQuery,
}: {
  accessToken: string;
  shopId: string;
  nProducts: number;
  nVariantsPerProduct: number;
  searchQuery: string;
  cursor: string | null;
}): Promise<PaginatedGraphqlResponse<Product>> {
  return proxyShopifyGraphqlRequest({
    accessToken,
    shopId,
    query: getMultipleProductsQuery({
      nProducts,
      nVariantsPerProduct,
      cursor,
      searchQuery,
      includeBrackets: true,
    }),
  }).then((gqlRes) =>
    parseGqlResponse({
      gqlRes,
      parser: getMultipleSemiShallowProductsFromGqlResponse,
    })
  );
}

export async function getShopifyProductSemiShallow({
  accessToken,
  shopId,
  productId,
  nVariants,
}: {
  accessToken: string;
  shopId: string;
  productId: string;
  nVariants: number;
}): Promise<Product> {
  return proxyShopifyGraphqlRequest({
    accessToken,
    shopId,
    query: getProductQuery({ productId, nVariants }),
  }).then((gqlRes) =>
    parseGqlResponse({ gqlRes, parser: getShallowProductFromGqlResponse })
  );
}

export function getMultipleProductsQuery({
  nProducts,
  nVariantsPerProduct,
  searchQuery,
  cursor,
  includeBrackets,
}: {
  nProducts: number;
  nVariantsPerProduct: number;
  searchQuery: string;
  cursor: string | null;
  includeBrackets: boolean;
}) {
  const cursorString = cursor ? `, after: "${cursor}"` : "";
  const query = searchQuery ? `, query: "*${searchQuery}*"` : "";
  const inner = `
      products(first:${nProducts}${cursorString}${query}) {
        pageInfo {
          hasNextPage
        }
        edges {
          cursor
          node {
            ${getSemiShallowProductNodeQuery({
              nVariants: nVariantsPerProduct,
            })}
          }
        } 
      }`;
  if (includeBrackets) {
    return `{${inner}}`;
  }
  return inner;
}

function getProductQuery({
  productId,
  nVariants,
}: {
  productId: string;
  nVariants: number;
}) {
  return `{
        product(id:"gid://shopify/Product/${productId}") {
            ${getSemiShallowProductNodeQuery({ nVariants })}
        }
    }`;
}

function getSemiShallowProductNodeQuery({ nVariants }: { nVariants: number }) {
  return `
    ${getShallowProductNodeQuery()}
    variants(first:${nVariants}) {
      edges {
        node {
          ${getVariantNodeQuery()}
        }
      }
    }
  `;
}

function getShallowProductNodeQuery() {
  return `
    id
    title
    featuredImage {
        transformedSrc
    }
    hasOutOfStockVariants
    variantsCount {
        count
      }
    status
    `;
}

const getMultipleSemiShallowProductsFromGqlResponse: GqlResponseParser<
  PaginatedGraphqlResponse<Product>
> = ({ data }) => {
  const { edges, pageInfo } = data.products;
  const parsedProducts: Product[] = [];
  for (const { node } of edges) {
    const product = getShopifyProductFromNode({ node });
    parsedProducts.push(product);
  }
  return {
    lastCursor: getLastCursorFromEdges({ edges }),
    data: parsedProducts,
    hasMoreItems: pageInfo.hasNextPage,
  };
};

const getShallowProductFromGqlResponse: GqlResponseParser<Product> = ({
  data,
}) => {
  const { product } = data;
  return getShopifyProductFromNode({ node: product });
};

export function getShopifyProductFromNode({ node }: { node: any }): Product {
  const img = node.featuredImage?.transformedSrc;
  return {
    id: getIdFromGqlId(node.id),
    imgSrc: img ? img : "",
    type: ResourceType.product,
    name: node.title,
    variants: node.variants.edges.map((edge: any) =>
      getVariantFromNode(edge.node)
    ),
    nVariants:
      node.variantsCount && node.variantsCount.count
        ? node.variantsCount.count
        : 0,
    hasOutOfStockVariants: node.hasOutOfStockVariants,
    status:
      node.status === "ACTIVE" ? ProductStatus.active : ProductStatus.draft,
  };
}
