export const CollectionNames = {
  shops: "shops",
  translations: "translations",
  offers: "offers",
  accessTokens: "accessTokens",
  admin: "admin",
  users: "users",
  activeOfferTriggers: "activeOfferTriggers",
};

export class FirestoreError extends Error {}
